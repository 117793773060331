import { RouterStore } from './stores/router';

const loadedData: { [key: string]: any } = {};
const sessionParams = new Set([
  'delegate',
  'skipLoadingQuote',
  'lastUrl',
  'lastProductUrl',
  'product',
  'interval',
  'currency',
  'invite',
  'lastUrlBeforeNaNoAuth',
  'dontSync',
  'importTemplate',
  'referralCode',
  'coupon',
  'code', // google auth return token
  'irclickid', // Impact Affiliate click ID
]);
const dontSet = new Set(['skipLoadingQuote']);

export function init(name: string, defaultValue?: any) {
  try {
    loadedData[name] = JSON.parse(sessionStorage.getItem(name)) || defaultValue;
  } catch (e) {
    loadedData[name] = defaultValue;
  }
}

export function get(name: string, key?: string) {
  const data = loadedData[name];
  Promise.resolve().then(() => sessionStorage.setItem(name, JSON.stringify(data)));

  if (key) {
    return data[key] || (data[key] = {});
  } else {
    return data;
  }
}

export function peek(name: string, key: string) {
  const data = loadedData[name];

  if (key) {
    return data[key];
  } else {
    return data;
  }
}

export function pullSessionFromUrl(router: RouterStore) {
  const query = router.getQuery();
  let key: string;
  let value: any;
  let hasAny = false;
  for ([key, value] of query) {
    if (sessionParams.has(key)) {
      query.delete(key);
      hasAny = true;
      if (!value) value = true;
      else if (value[0] === '{')
        try {
          value = JSON.parse(value);
        } catch (e) { }
      if (!dontSet.has(key)) {
        sessionStorage[key] = value;
      }
    }
  }
  if (hasAny) {
    const params = query.toString();
    router.navigate(router.get().url.replace(/\?.*/, params ? `?${params}` : ''), true);
  }
}
