import { StatefulPromise } from '@dabble/data/collective/stateful-promise';
import { createId } from 'crypto-id';
import { Delta, Editor, Line, Op, isEqual } from 'typewriter-editor';
import { beyondGrammar } from './grammar-api';
import { GrammarLineFormat, Issue } from './grammar-types';
import { getIssueRange } from './grammar-updates';

const CHARS = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
const EMPTY_HASH = 'X3QaaL7IF';
const enroute = new Map<string, StatefulPromise<Issue[]>>();

// Properties to exclude when comparing if issues are the same
const excludeProps = new Set(['dismissed']);

/**
 * Check the spelling and grammar of the current editor, skipping lines that haven't changed.
 */
export function checkEditor(editor: Editor) {
  editor.doc.lines.forEach(line => checkLine(editor, line, beyondGrammar.languageIsoCode));
}

/**
 * If a check is queued for a line, cancel it.
 */
export function cancelLineCheck(line: Line) {
  const promise = enroute.get(line.id);
  if (promise) {
    enroute.delete(line.id);
    promise.cancel();
  }
}

/**
 * Check the spelling and grammar of a line of text.
 */
async function checkLine(editor: Editor, line: Line, language: string): Promise<void> {
  const text = getLineText(line.content);
  const hash = hashString(text);
  cancelLineCheck(line);

  // Skip if the line hasn't changed
  if (!hasLineChanged(line, hash, language)) {
    return;
  }

  // Check the line for grammar issues, storing the stateful promise to be canceled later if needed
  const promise = beyondGrammar.check(text);
  enroute.set(line.id, promise);
  promise.finally(() => enroute.delete(line.id));

  const result = await promise;
  const lineNow = editor.doc.getLineBy(line.id);

  if (!lineNow || (lineNow !== line && hash !== hashString(getLineText(lineNow.content)))) {
    // The line has changed while the call was enroute. Another check is lined up already to happen. Remember, the
    // checkLine function may be slow to return if the document is big and it is in queue behind many other checks.
    return;
  }

  const change = editor.change;
  const [lineStart] = editor.doc.getLineRange(lineNow);
  const issues: Record<string, Issue> = {};

  // Clear out previous grammar issues
  const oldIssues = (lineNow.attributes.grammar as GrammarLineFormat)?.issues || {};
  const newIssueIds = new Set<string>();
  Object.keys(oldIssues).forEach(key => (issues[key] = null));

  // Add new grammar issues
  result.forEach(issue => {
    for (const [issueId, oldIssue] of Object.entries(oldIssues)) {
      const [startPos, endPos] = getIssueRange(editor.doc, lineNow, issueId);
      if (isEqual({ ...oldIssue, startPos, endPos }, issue, { excludeProps })) {
        delete issues[issueId]; // Delete the nullified issue so that it won't be cleared out (leave it in)
        return;
      }
    }
    const issueId = createId(4);
    newIssueIds.add(issueId);
    issues[issueId] = issue;
  });

  // Clear out existing grammar issues that are no longer present
  let pos = lineStart;
  lineNow.content.ops.forEach(op => {
    const length = Op.length(op);
    const id = op.attributes?.grammar;
    if (id && (issues[id] === null || !oldIssues[id])) {
      change.formatText([pos, pos + length], { grammar: null });
    }
    pos += length;
  });

  // Apply the new grammar issues
  newIssueIds.forEach(issueId => {
    const issue = issues[issueId];
    const { startPos, endPos } = issue;
    delete issue.startPos;
    delete issue.endPos;
    change.formatText([lineStart + startPos, lineStart + endPos], { grammar: issueId });
  });

  // Update the line hash so we know the issues are up to date with this line's text
  change.formatLine(lineStart, { ...line.attributes, grammar: { h: hash, l: language, issues } });
  change.apply('grammar');
}

/**
 * Check if the line has changed since the last grammar check.
 */
function hasLineChanged(line: Line, hash: string, language: string) {
  const grammar = line.attributes.grammar;
  return !grammar || grammar.h !== hash || grammar.l !== language;
}

/**
 * Get the text of a line from its content Delta.
 */
function getLineText(content: Delta) {
  return (
    (content &&
      content.map(op => (typeof op.insert === 'string' ? op.insert : (op.insert as any).br ? '\n' : ' ')).join('')) ||
    ''
  ).trim();
}

/**
 * A very fast string hashing algorithm for a 9-character base62 hash to compare line changes.
 */
function hashString(str: string) {
  if (!str) return EMPTY_HASH; // The hash of an empty string is always the same
  // Compute a 53-bit hash of the string using 2 32-bit integers
  let low = 0xdeadbeef,
    high = 0x41c6ce57;
  for (let i = 0; i < str.length; i++) {
    const ch = str.charCodeAt(i);
    low = Math.imul(low ^ ch, 2654435761);
    high = Math.imul(high ^ ch, 1597334677);
  }
  low = Math.imul(low ^ (low >>> 16), 2246822507);
  low ^= Math.imul(high ^ (high >>> 13), 3266489909);
  high = Math.imul(high ^ (high >>> 16), 2246822507);
  high ^= Math.imul(low ^ (low >>> 13), 3266489909);

  // Combine the two 32-bit integers into a single 53-bit integer (max safe integer)
  let num = 4294967296 * (2097151 & high) + (low >>> 0);
  let base62 = '';
  while (num > 0) {
    const remainder = num % 62;
    base62 += CHARS[Number(remainder)];
    num = Math.floor(num / 62);
  }
  return base62.padStart(9, '0');
}
