import {
  currentDocId,
  getNow,
  hideLeftNav,
  hideRightNav,
  leftNavWidth,
  observe,
  plugins,
  projectStore,
  sidebar,
  sidebarWidth,
  workspaceWidth,
} from '@dabble/app';
import ProjectPatch from '@dabble/data/project-patch';
import { signal } from '@dabble/data/signals';
import { locallyStoredWritable } from '@dabble/data/stores/locally-stored-writable';
import { ProjectStore } from '@dabble/data/stores/project';
import { derived, Readable, writable } from '@dabble/data/stores/store';
import { Doc } from '@dabble/data/types';
import { createId } from '@dabble/data/uuid';
import { getImmutableValue, makeChanges } from '@dabble/util/immutable';
import { isEqual } from 'lodash';
import { Delta, Editor, EditorRange, LineOp, Op, TextDocument } from 'typewriter-editor';

const EMPTY: CommentMap = {};

export interface CommentReply {
  uid: string;
  content: string;
  created: number;
}

export interface Comment extends CommentReply {
  id: string;
  docId: string;
  field: string;
  text: string; // The text this comment was first created on in the doc
  replies: CommentReply[];
  resolved?: boolean;
}

export interface CommentMap {
  [id: string]: Comment;
}

export interface CommentsStore extends Readable<CommentMap> {
  comment(editor: Editor, range: EditorRange, content: string, id?: string): Promise<void>;
  reply(id: string, content: string): Promise<void>;
  updateComment(id: string, content: string): Promise<void>;
  updateReply(id: string, index: number, content: string): Promise<void>;
  resolveComment(id: string, unresolve?: boolean): Promise<void>;
  deleteComment(id: string): Promise<void>;
  deleteReply(id: string, replyIndex?: number): Promise<void>;
}

export const [commentsStore, commentsInText] = createCommentsStores(projectStore, plugins.stores.uid, currentDocId);
export const showComments = locallyStoredWritable('showComments', false);
export const selectedCommentId = writable<string>('');
export const chosenCommentId = writable<string>('');
export const activeCommentId = derived([chosenCommentId, selectedCommentId], ([c, s]) => c || s);
export const updateCommentDisplay = signal();
plugins.register({ comments: commentsStore, commentsInText, showComments, chosenCommentId, activeCommentId });

// Holds all the comments for currently displayed editors and their position
export function createCommentsStores(
  projectStore: ProjectStore,
  uidStore: Readable<string>,
  currentDocId: Readable<string>
): [CommentsStore, Readable<Set<string>>] {
  const commentsInText = writable(new Set<string>());
  let comments: CommentMap = EMPTY;
  let lastDocId: string;

  // This store is a map of all comments by id in the current doc and all of its decendents (including virtual docs)
  const { get, subscribe } = derived([projectStore, currentDocId], ([{ docs, childrenLookup }, currentDocId]) => {
    if (currentDocId !== lastDocId) {
      lastDocId = currentDocId;
    }
    if (!currentDocId) {
      return (comments = EMPTY);
    }
    const ids = new Set(Object.keys(comments));

    makeChanges(() => {
      const inText = new Set<string>();
      addComments(docs[currentDocId]);
      if (ids.size) {
        comments = getImmutableValue(comments);
        ids.forEach(id => delete comments[id]);
      }
      if (!isEqual(inText, commentsInText.get())) {
        commentsInText.set(inText);
      }

      function addComments(doc: Doc) {
        if (!doc) return;
        if (doc.comments) {
          const fields = new Set<string>();
          Object.values(doc.comments as CommentMap).forEach(comment => {
            ids.delete(comment.id);
            fields.add(comment.field || 'body');
            if (comments[comment.id] !== comment) {
              // Doing it this way will make comments not change if no comments were updated in the project
              comments = getImmutableValue(comments);
              comments[comment.id] = comment;
            }
          });

          fields.forEach(field => {
            const text = doc[field];
            if (!(text instanceof TextDocument)) return;
            const iter = LineOp.iterator(text.lines);
            let op: Op;
            while ((op = iter.next()) && op.retain !== Infinity) {
              const ids = op.attributes?.comment;
              if (ids) {
                Object.keys(ids).forEach(id => inText.add(id));
              }
            }
          });
        }
        childrenLookup[doc.id]?.forEach(addComments);
      }
    });

    return comments;
  });

  async function comment(editor: Editor, range: EditorRange, content: string, id?: string) {
    const { id: docId, field } = editor.identifier;
    const doc = projectStore.getDoc(docId);

    const patch = projectStore.patch();
    if (!doc.comments) {
      patch.patch.add(`/docs/${docId}/comments`, {});
    }
    let text = editor.getText(range).trim();
    if (text.length > 80) {
      text = text.slice(0, 77) + '...';
    }
    const comment: Comment = {
      id: id || createId(4, doc.comments, docId),
      uid: uidStore.get(),
      docId,
      field,
      text,
      content,
      created: getNow(),
      replies: [],
    };
    patch.patch.add(`/docs/${docId}/comments/${comment.id}`, comment);
    await patch.save();
    editor.formatText({ comment: { [comment.id]: true } }, range);
    await projectStore.commitQueuedTextChanges(docId);
  }

  async function reply(id: string, content: string) {
    const { path, comment } = findComment(id);
    const uid = uidStore.get();
    const patch = projectStore.patch();
    patch.patch.add(`${path}/replies/${comment.replies.length}`, { uid, content, created: getNow() });
    await patch.save();
  }

  async function updateComment(id: string, content: string) {
    const { comment, path } = findComment(id);
    const uid = uidStore.get();
    if (comment.uid !== uid) throw new Error(`You do not have permission to modify this comment`);
    const patch = projectStore.patch();
    patch.patch.add(`${path}/content`, content);
    await patch.save();
  }

  async function updateReply(id: string, index: number, content: string) {
    const { comment, path } = findComment(id);
    const uid = uidStore.get();
    const reply = comment.replies[index];
    if (reply.uid !== uid) throw new Error(`You do not have permission to modify this comment`);
    const patch = projectStore.patch();
    patch.patch.add(`${path}/replies/${index}/content`, content);
    await patch.save();
  }

  async function resolveComment(id: string, unresolve = false) {
    const { comment, path, docId } = findComment(id);
    if (!comment) return;
    const patch = projectStore.patch();
    patch.patch.add(`${path}/resolved`, !unresolve);
    changeCommentRange(projectStore.getDoc(docId), comment.id, patch, unresolve);
    await patch.save();
    projectStore.forceTextUpdate();
  }

  async function deleteComment(id: string) {
    const { comment, path, docId } = findComment(id, true);
    if (!comment) return;
    const patch = projectStore.patch();
    patch.patch.remove(path);
    changeCommentRange(projectStore.getDoc(docId), comment.id, patch, null);

    await patch.save();
    projectStore.forceTextUpdate();
  }

  async function deleteReply(id: string, replyIndex?: number) {
    const { comment, path } = findComment(id, true);
    if (!comment) return;
    const patch = projectStore.patch();
    patch.patch.remove(`${path}/replies/${replyIndex}`);
    await patch.save();
  }

  function findComment(id: string, dontThrow?: boolean) {
    const comment = comments[id];
    const docId = comment?.docId;
    if ((!comment || !docId) && !dontThrow) throw new Error(`Comment ${id} does not exist`);
    const path = `/docs/${docId}/comments/${id}`;
    return { comment, path, docId };
  }

  function changeCommentRange(doc: Doc, id: string, patch: ProjectPatch, value: boolean | null) {
    // Find and remove reference in text document
    const keys = Object.keys(doc);
    for (const field of keys) {
      if (!(doc[field] instanceof TextDocument)) continue;

      const iter = LineOp.iterator(doc[field].lines);
      let op: Op,
        index = 0,
        from: number,
        to: number;

      // Find the comment in the text document if it exists
      while ((op = iter.next()) && op.retain !== Infinity) {
        const length = Op.length(op);
        const comments = op.attributes?.comment;
        if (comments && id in comments) {
          if (from === undefined) from = index;
          to = index + length;
        }
        index += length;
      }

      if (to) {
        const format = new Delta().retain(from).retain(to - from, { comment: { [id]: value } });
        patch.changeText(doc.id, field, format);
        break;
      }
    }
  }

  return [
    {
      comment,
      reply,
      updateComment,
      updateReply,
      resolveComment,
      deleteComment,
      deleteReply,
      get,
      subscribe,
    },
    commentsInText,
  ];
}

const PAGE_AND_COMMENT_WIDTH = 1118;

const docAreaWidth = derived(
  [workspaceWidth, hideLeftNav, hideRightNav, sidebarWidth, leftNavWidth],
  ([workspaceWidth, hideLeftNav, hideRightNav, sidebarWidth, leftNavWidth]) => {
    const leftWidth = hideLeftNav ? 0 : leftNavWidth;
    const rightWidth = hideRightNav ? 0 : sidebarWidth + 50; // 50 for the toolbar
    if (!workspaceWidth) return workspaceWidth;
    return workspaceWidth - leftWidth - rightWidth;
  }
);

export const canShowCommentsAndSidebar = derived(
  docAreaWidth,
  docAreaWidth => !docAreaWidth || docAreaWidth > PAGE_AND_COMMENT_WIDTH
);

let lastChanged = 'sidebar';
observe([sidebar, hideRightNav], () => (lastChanged = 'sidebar'));
observe(showComments, () => (lastChanged = 'comments'));

observe(
  [showComments, sidebar, canShowCommentsAndSidebar],
  ([showCommentsValue, sidebarValue, canShowCommentsAndSidebar]) => {
    if (showCommentsValue && sidebarValue && !canShowCommentsAndSidebar) {
      if (lastChanged === 'sidebar') {
        showComments.set(false);
      } else {
        sidebar.set('');
      }
    }
  }
);
